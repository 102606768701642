import axios from "axios";
import React, {useEffect, useRef, useState} from "react";
import validator from 'validator';
import V56spinner from './v56_spinner';
import {
    useRecoilState,
} from 'recoil';
import {
    currentPage_, urlRef_
} from "./App";
import { isExpired, decodeToken } from "react-jwt";
import { getFingerprint } from '@thumbmarkjs/thumbmarkjs';


const baseURL = "https://api3.vault56.com/v2";

export default function V56LoginFront() {

    const emailInput = useRef(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [emailErrorMessage, setEmailErrorMessage] = useState('');
    const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
    const [user, setUser] = useState('');
    const [pass, setPass] = useState('');
    const [currentPage, setCurrentPage] = useRecoilState(currentPage_);
    const [urlRef, setUrlRef] = useRecoilState(urlRef_);
    const [fingerprint, setFingerprint] = useState('');

    useEffect(() => {
        (async () => {
            try {
                await getFingerprint().then((fingerprint) => {
                    console.log('fingerprint: ' + fingerprint);
                    setFingerprint(fingerprint);
                });
            } catch (e) {
                setLoading(false);
                setFingerprint('vault56');
            }
        })();
        const tUrl = new URLSearchParams(window.location.search).get('app');
        const url = tUrl === null ? 'dashboard' : tUrl;
        if (
            url === 'maintenance' ||
            url === 'passwords' ||
            url === 'dashboard' ||
            url === 'crm' ||
            url === 'storage'
        ) {
            setUrlRef(url);
        }
    }, [])

    useEffect(() => {
        (async () => {
            if (fingerprint !== '') {
                if (await checkTokenValidity()) {
                    if (urlRef !== null && urlRef !== '') {
                        if (
                            urlRef === 'maintenance' ||
                            urlRef === 'cases' ||
                            urlRef === 'passwords' ||
                            urlRef === 'dashboard' ||
                            urlRef === 'crm' ||
                            urlRef === 'storage'
                        ) {
                            window.location.href = urlRef;
                        } else {
                            setLoading(false);
                            emailInput.current.focus();
                        }
                    }
                } else {
                    setLoading(false);
                    if (emailInput.current !== null) {
                        emailInput.current.focus();
                    }
                }
            }
        })();
    },[fingerprint]);

    async function checkTokenValidity() {

        // TEMP
        //setLoading(false);
        //return false;
        // END TEMP

        // Make sure we have token/secret. If we do NOT have a token / secret, we will return false
        if (localStorage.getItem('v56_token') === null || localStorage.getItem('v56_secret') === null) {
            setLoading(false);
            return false;
        }

        try {
            let response = await axios.get(baseURL + '/auth/checkauth', {
                params: {
                    token: localStorage.getItem('v56_token'),
                    secret: localStorage.getItem('v56_secret'),
                    fingerprint: fingerprint,
                }
            }).catch(() => {
                setLoading(false);
                return false;
            });

            if (response.status === 200) {
                return true;
            } else {
                setLoading(false);
                return false;
            }
        } catch (e) {
            setLoading(false);
            return false;
        }
    }

    function validateEmail(e) {
        if (validator.isEmail(e)) {
            setEmailErrorMessage('')
        } else {
            setEmailErrorMessage('Invalid email');
        }
    }

    function doLogin() {
        setLoading(true)
        setError('')
        setEmailErrorMessage('')
        setPasswordErrorMessage('')

        if (user.trim() === '') {
            setLoading(false)
            setEmailErrorMessage('Enter a valid email address');
            emailInput.current.focus();
            return;
        }

        if (pass.trim() === '') {
            setLoading(false)
            setPasswordErrorMessage('Enter a password');
            return;
        }

        axios.get(baseURL + '/auth/login', {
            params: {
                username: user,
                password: pass,
                fingerprint: fingerprint
            }
        })
            .then((response) => {
                if (response.status === 201) {
                    localStorage.setItem('v56_token', response.data.id.token);
                    localStorage.setItem('v56_secret', response.data.id.secret);
                    window.location.href = urlRef;
                } else {
                    setLoading(false);
                    setError(response.data['message']);
                    localStorage.removeItem('v56_token');
                    localStorage.removeItem('v56_secret');
                    localStorage.removeItem('v56_session');
                }

            })
            .catch(error => {
                setError('Login failed');
                setLoading(false)
                localStorage.removeItem('v56_token');
                localStorage.removeItem('v56_secret');
                localStorage.removeItem('v56_session');
            })
    }

    function onKeyDown(e) {
        if (e.key.toString() === "Enter") {
            doLogin();
        }
    }

    if (loading) {
        return (
            <V56spinner/>
        )
    } else {
        return (
            <div className="flex flex-col justify-center min-h-full py-12 sm:px-6 lg:px-8">

                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                    <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 space-y-6">
                        <div className="sm:mx-auto sm:w-full sm:max-w-md">
                            <img
                                className="mx-auto h-12 w-auto"
                                src="https://cdn.vault56.com/images/56_logo.png"
                                alt="VAULT 56"
                            />
                            <h2 className="mt-6 text-center text-2xl font-extrabold text-gray-900">Honest -
                                Secure - Reliable</h2>
                        </div>
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                Email address
                            </label>
                            <div className="mt-1">
                                <input
                                    ref={emailInput}
                                    onKeyDown={(e) => onKeyDown(e)}
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    disabled={loading}
                                    onChange={(e) => {
                                        validateEmail(e.target.value);
                                        setUser(e.target.value);
                                    }}
                                    required
                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                            </div>
                            <span style={{color: 'red'}}>{emailErrorMessage}</span>
                        </div>

                        <div>
                            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                Password
                            </label>
                            <div className="mt-1">
                                <input
                                    id="password"
                                    onKeyDown={(e) => onKeyDown(e)}
                                    name="password"
                                    type="password"
                                    autoComplete="current-password"
                                    disabled={loading}
                                    onChange={(e) => {
                                        setPass(e.target.value);
                                    }}
                                    required
                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                            </div>
                            <span style={{color: 'red'}}>{passwordErrorMessage}</span>
                        </div>

                        <span style={{color: 'red'}}>{error}</span>

                        <div className="flex items-center justify-between">
                            <div className="flex items-center">
                                <input
                                    id="remember-me"
                                    name="remember-me"
                                    type="checkbox"
                                    disabled={loading}
                                    className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                                />
                                <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                                    Remember me
                                </label>
                            </div>

                            <div className="text-sm"
                                 hidden={loading}
                            >
                                <a href="#"
                                onClick={() => {
                                    setCurrentPage('password')
                                }}
                                >
                                    Forgot your password?
                                </a>
                            </div>
                        </div>

                        <div>
                            <button
                                onClick={() => doLogin()}
                                disabled={emailErrorMessage !== ''}
                                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#5f2881] hover:bg-[#6f4488] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                Sign in
                            </button>
                        </div>

                        <div className="text-center">
                            Don't have an account?{' '}
                            <a href="/signup">
                                Sign up
                            </a>
                        </div>

                        <div className="text-center">
                            <a href="https://www.vault56.com">
                                Go to vault56.com
                            </a>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}
