import V56LoginFront from "./v56-login-front";
import {
    useRecoilState,
} from 'recoil';
import {
    currentPage_
} from "./App";
import V56ChangePass from "./v56-change-pass";
import V56LoginBack from "./v56-login-back";

export default function V56Login() {

    const [currentPage, setCurrentPage] = useRecoilState(currentPage_);

    if (currentPage === 'login') {
        return (
            <V56LoginFront/>
        )
    } else {
        return (
            <V56LoginBack/>
        )
    }
}