import './App.css';
import V56Login from "./v56-login";
import {
  RecoilRoot,
  atom,
} from 'recoil';

export const currentPage_ = atom({
    key: 'currentPage_',
    default: 'login',
});

export const urlRef_ = atom({
    key: 'urlRef_',
    default: 'dashboard',
});

function App() {
  return (

      <RecoilRoot>
        <V56Login />
      </RecoilRoot>

  );
}

export default App;
