import React, {useEffect, useRef, useState} from "react";
import validator from 'validator';
import V56spinner from './v56_spinner';
import {
    useSetRecoilState,
} from 'recoil';
import {
    currentPage_
} from "./App";
import {v56a_change_password_request} from "./v56api";

export default function V56LoginBack() {

    const emailInput = useRef(null);
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [requestSent, setRequestSent] = useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState('');
    const setCurrentPage = useSetRecoilState(currentPage_);

    useEffect(() => {
        emailInput.current.focus();
    }, [])

    function validateEmail(e) {
        if (validator.isEmail(e)) {
            setEmailErrorMessage('')
        } else {
            setEmailErrorMessage('Invalid email');
        }
    }

    async function changePass() {
        setLoading(true)
        setError('')
        setEmailErrorMessage('')

        if (email.trim() === '') {
            setLoading(false)
            setEmailErrorMessage('Enter a valid email address');
        }

        // Send password change request
        const retVal = await v56a_change_password_request(
            email
        );
        setLoading(false);
        if (retVal.status === 201) {
            setRequestSent(true);
        } else if (retVal.status === 400 && retVal.data['code'] === 4005) {
            // No such email
            setEmailErrorMessage('This email address is not registered');
        } else if (retVal.status === 400 && retVal.data['code'] === 4002) {
            // Validation error
            setLoading(false);
            if (retVal.data['parameter'] === 'email') {
                setEmailErrorMessage('Enter a valid email address');
            }
        } else {
            // Unknown error
            setRequestSent(false);
            setLoading(false);
            setError('Request failed, please try again later');
        }
    }

    function onKeyDown(e) {
        if (e.key.toString() === "Enter") {
            changePass();
        }
    }

    if (loading) {
        return (
            <V56spinner/>
        )
    } else if (requestSent) {
        return (
                <div className="flex flex-col justify-center min-h-full py-12 sm:px-6 lg:px-8">

                    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 space-y-6">
                            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                                <img
                                    className="mx-auto h-12 w-auto"
                                    src="https://cdn.vault56.com/images/56_logo.png"
                                    alt="VAULT 56"
                                />
                                <h2 className="mt-6 text-center text-2xl font-extrabold text-gray-900">Check your email</h2>
                                <p>A verification email has been sent to your email address. Check your spam folder and make sure it has not ended up there.</p>
                            </div>
                            <div className="flex items-center justify-between">
                                <div className="text-sm"
                                     hidden={loading}
                                >
                                    <a href="#"
                                       onClick={() => {
                                           setCurrentPage('login')
                                       }}
                                    >
                                        Back to login
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        );
    } else {
        return (
            <div className="flex flex-col justify-center min-h-full py-12 sm:px-6 lg:px-8">

                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                    <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 space-y-6">
                        <div className="sm:mx-auto sm:w-full sm:max-w-md">
                            <img
                                className="mx-auto h-12 w-auto"
                                src="https://cdn.vault56.com/images/56_logo.png"
                                alt="VAULT 56"
                            />
                            <h2 className="mt-6 text-center text-2xl font-extrabold text-gray-900">Change Password</h2>
                        </div>
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                Email address
                            </label>
                            <div className="mt-1">
                                <input
                                    ref={emailInput}
                                    onKeyDown={(e) => onKeyDown(e)}
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    disabled={loading}
                                    onChange={(e) => {
                                        validateEmail(e.target.value);
                                        setEmail(e.target.value);
                                    }}
                                    required
                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                            </div>
                            <span style={{color: 'red'}}>{emailErrorMessage}</span>
                        </div>

                        <span style={{color: 'red'}}>{error}</span>

                        <div className="flex items-center justify-between">
                            <div className="text-sm"
                                 hidden={loading}
                            >
                                <a href="#"
                                onClick={() => {
                                    setCurrentPage('login')
                                }}
                                >
                                    Back to login
                                </a>
                            </div>
                        </div>

                        <div>
                            <button
                                onClick={() => changePass()}
                                disabled={emailErrorMessage !== ''}
                                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#5f2881] hover:bg-[#6f4488] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                Request password change
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}
