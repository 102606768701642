import axios from "axios";

const baseURL = "https://api3.vault56.com/v2";

export async function v56a_change_password_request(
    email
) {

    const url =
        baseURL + '/register/change/password/request';

    const bodyFormData = new FormData();
    bodyFormData.append('email', email);
    try {
        return await axios
            .post(url, bodyFormData, {
                data: bodyFormData
            });
    } catch (e) {
        return e.response;
    }

}
